import InfiniteScroll from "react-infinite-scroll-component";
import ScreenItemSearchResult from "src/components/ScreenItemSearchResult";
import {
  getScreenContentItem,
  getScreenStatus,
} from "src/helpers/screenHelper";
import { useAppContext } from "src/hooks/useAppContext";
import {
  ScreenStatus,
  Screen,
  ScreensByScreenGroupIdAndOrgIdFragment,
} from "src/types.g";
import { ScreenPickerProps } from "../index";
import { ScreenPickerEmptyState } from "./ScreenPickerEmptyState";
import { useMemo } from "react";

interface ScreenPickerScreenListProps {
  customContainer: React.MutableRefObject<any> | null;
  handleAddScreen: () => void;
  screenPickerCallback: (checked: boolean, id: string) => void;
  screens: ScreensByScreenGroupIdAndOrgIdFragment[];
  selectedScreens: ScreenPickerProps["selectedScreens"];
  loadMore: () => void;
  hasNextPage: boolean;
  isSearch: boolean;
  loading: boolean;
  clearSearch: () => void;
}

export const ScreenPickerScreenList = (props: ScreenPickerScreenListProps) => {
  const context = useAppContext();

  const screensList = props.customContainer
    ? props.screens
        .filter((screen) => screen.status === ScreenStatus.Live)
        .map((screen) => {
          const groupName = screen?.screenGroupByScreenGroupId?.name || "";
          const contentItem = getScreenContentItem(screen as Screen, context);
          return (
            <ScreenItemSearchResult
              key={`screen-picker-item-${screen.id}`}
              url={`/screens/${screen.id}`}
              checked={props.selectedScreens.some(
                (item) => item.id === screen.id
              )}
              id={screen.id}
              name={screen.name!}
              model={screen.deviceModel ?? ""}
              platform={screen.devicePlatform ?? ""}
              group={
                screen.screenGroupByScreenGroupId!.isPrimary
                  ? "Ungrouped"
                  : groupName
              }
              status={getScreenStatus(screen!)}
              tags={screen.tags as string[]}
              castItem={screen.castByCastId ?? undefined}
              contentItem={contentItem}
              isCasting={Boolean(screen.castId)}
              callBack={props.screenPickerCallback}
            />
          );
        })
    : [];

  const hasScreens = screensList.length > 0;

  const infiniteScrollStyle = useMemo(
    () => ({ overflow: "auto", height: "100%" }),
    []
  );

  return (
    <>
      {hasScreens ? (
        <InfiniteScroll
          style={infiniteScrollStyle}
          dataLength={props.screens.length ?? 0}
          next={props.loadMore}
          scrollableTarget="lazy-ref-container"
          hasMore={props.hasNextPage}
          loader={
            <div className="layout-list" key="loading-message">
              Loading...
            </div>
          }
        >
          {screensList}
        </InfiniteScroll>
      ) : (
        <ScreenPickerEmptyState
          isSearch={props.isSearch}
          loading={props.loading}
          clearSearch={props.clearSearch}
          onAddScreen={props.handleAddScreen}
        />
      )}
    </>
  );
};
